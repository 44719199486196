import {iosVhFix} from './utils/ios-vh-fix';
import {initModals} from './modules/modals/init-modals';
import {initAccordions} from './modules/init-accordion';
import {initTabs} from './modules/init-tabs';
// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    initAccordions();
    initTabs();

    $('input[type="tel"]').inputmask('+7 999 999 99 99');

    $('[data-burger]').on('click', function() {
      let $this = $(this),
          $list = $('[data-burger-list]'),
          $overlay = $('.header-overlay');

      $('html').toggleClass('of-hidden');
      $this.toggleClass('is-active');
      $list.toggleClass('is-active');
      $overlay.fadeToggle(300);
    });

    let examples = document.querySelectorAll('.examples__slider');
    if(examples) {
      examples.forEach(function(example) {
        let swiper = new Swiper(example, {
          
          loop: true,
          navigation: {
            prevEl: example.closest('.examples').querySelector('.--prev'),
            nextEl: example.closest('.examples').querySelector('.--next'),
          },
          breakpoints: {
            0: {
              slidesPerView: 1,
              pagination: {
                el: '.examples__dots'
              },
            },
            1020: {
              slidesPerView: 3
            },
            1340: {
              slidesPerView: 'auto',
              centeredSlides: true,
              spaceBetween: 30,
            }
          }
        });
      });
    }

    let detail = document.querySelectorAll('.detail__slider');
    if(detail) {
      detail.forEach(function(item) {
        let swiper = new Swiper(item, {
          slidesPerView: 1,
          loop: true,
          pagination: {
            el: item.querySelector('.detail__dots'),
          }
        });
      });
    }

    let detailBottom = document.querySelectorAll('.detail__bottom-slider');
    if(detailBottom) {
      detailBottom.forEach(function(item) {
        let swiper = new Swiper(item, {
          
          loop: true,
          navigation: {
            prevEl: item.querySelector('.--prev'),
            nextEl: item.querySelector('.--next'),
          },
          breakpoints: {
            0: {
              slidesPerView: 1,
              pagination: {
                el: '.examples__dots'
              },
            },
            1020: {
              slidesPerView: 3
            },
            1340: {
              slidesPerView: 'auto',
              centeredSlides: true,
              spaceBetween: 30,
            }
          }
        });
      });
    }

    if(window.innerWidth < 746) {
      let solutionSlider = document.querySelector('.solutions__items');
      if(solutionSlider) {
        let swiper = new Swiper(solutionSlider, {
          slidesPerView: 1,
          pagination: {
            el: '.solutions__item-dots'
          }
        })
      }
    }

  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✅

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
